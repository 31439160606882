// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-behavioral-economics-index-js": () => import("./../../../src/pages/behavioral-economics/index.js" /* webpackChunkName: "component---src-pages-behavioral-economics-index-js" */),
  "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-others-js": () => import("./../../../src/pages/behavioral-economics/videos/how-we-are-affected-by-others.js" /* webpackChunkName: "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-others-js" */),
  "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-preference-js": () => import("./../../../src/pages/behavioral-economics/videos/how-we-are-affected-by-preference.js" /* webpackChunkName: "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-preference-js" */),
  "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-price-js": () => import("./../../../src/pages/behavioral-economics/videos/how-we-are-affected-by-price.js" /* webpackChunkName: "component---src-pages-behavioral-economics-videos-how-we-are-affected-by-price-js" */),
  "component---src-pages-behavioral-economics-videos-introduction-to-behavioral-economics-js": () => import("./../../../src/pages/behavioral-economics/videos/introduction-to-behavioral-economics.js" /* webpackChunkName: "component---src-pages-behavioral-economics-videos-introduction-to-behavioral-economics-js" */),
  "component---src-pages-economic-principles-index-js": () => import("./../../../src/pages/economic-principles/index.js" /* webpackChunkName: "component---src-pages-economic-principles-index-js" */),
  "component---src-pages-economic-principles-videos-how-algae-could-change-fossil-fuel-industry-js": () => import("./../../../src/pages/economic-principles/videos/how-algae-could-change-fossil-fuel-industry.js" /* webpackChunkName: "component---src-pages-economic-principles-videos-how-algae-could-change-fossil-fuel-industry-js" */),
  "component---src-pages-economic-principles-videos-the-impact-of-supply-chain-disruptions-js": () => import("./../../../src/pages/economic-principles/videos/the-impact-of-supply-chain-disruptions.js" /* webpackChunkName: "component---src-pages-economic-principles-videos-the-impact-of-supply-chain-disruptions-js" */),
  "component---src-pages-economic-principles-videos-why-the-us-needs-new-generation-of-farmers-js": () => import("./../../../src/pages/economic-principles/videos/why-the-us-needs-new-generation-of-farmers.js" /* webpackChunkName: "component---src-pages-economic-principles-videos-why-the-us-needs-new-generation-of-farmers-js" */),
  "component---src-pages-financial-markets-index-js": () => import("./../../../src/pages/financial-markets/index.js" /* webpackChunkName: "component---src-pages-financial-markets-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/Redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

