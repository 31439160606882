import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

/**
 * Outer wrapper used to load libraries once, for example the smoothscroll library.
 */
const PageWrapper = ({ children }) => {
  useEffect(() => {
    console.log('running smoothscroll2');
    smoothscroll.polyfill();
  }, []);
  return <>{children}</>;
};

export default PageWrapper;
